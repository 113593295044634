.scroll-on-hover-x {
  overflow: hidden;
}

.scroll-on-hover-x:hover {
  overflow-x: auto;
}

.scroll-on-hover-y {
  overflow: hidden;
}

.scroll-on-hover-y:hover {
  overflow-y: auto;
}

.hide-when-screen-shrinks {
  flex: 1;
  display: auto;
}
