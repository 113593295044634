.active-function-marker0 {
  background-color: #AFF8D8;
  position: absolute;
}

.active-function-marker1 {
  background-color: #FFCBC1;
  position: absolute;
}

.active-function-marker2 {
  background-color: #FBE4FF;
  position: absolute;
}

.active-function-marker3 {
  background-color: #D8FFD8;
  position: absolute;
}

.active-function-marker4 {
  background-color: #C4FAF8;
  position: absolute;
}

.active-function-marker5 {
  background-color: #E7FFAC;
  position: absolute;
}

.active-function-marker6 {
  background-color: #ACE7FF;
  position: absolute;
}

.active-function-marker7 {
  background-color: #FFABAB;
  position: absolute;
}

.active-function-marker8 {
  background-color: #FFB5E8;
  position: absolute;
}

.scroll-on-hover-x {
  overflow: hidden;
}

.scroll-on-hover-x:hover {
  overflow-x: auto;
}

.scroll-on-hover-y {
  overflow: hidden;
}

.scroll-on-hover-y:hover {
  overflow-y: auto;
}

.hide-when-screen-shrinks {
  flex: 1 1;
  display: auto;
}

